nav {
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;
  max-width: 80rem;

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
